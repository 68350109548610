<template>
  <div
    :class="
      twMerge(
        'mt-4',
        { 'opacity-60 pointer-events-none': disabled },
        wrapperClass
      )
    "
  >
    <label class="block">
      <Label :label="label" />

      <select
        v-bind="$attrs"
        ref="select"
        v-model="model"
        :name="name"
        :multiple="multiple"
        :disabled="disabled"
        :class="
          twMerge(
            'px-3 py-2 bg-white dark:bg-black dark:bg-opacity-30 border border-neutral-200 dark:border-neutral-700 block w-full rounded-md focus:!border-grape focus:ring-0',
            label ? 'mt-1' : '',
            $attrs.class
          )
        "
        v-on="listenersWithoutInput"
      >
        <option
          v-for="(option, key) in mappedOptions"
          :key="key"
          :value="option.value"
          :disabled="option.disabled"
        >
          {{ option.label }}
        </option>
      </select>
      <p
        v-if="description"
        class="text-xs mt-1 text-neutral-500 dark:text-neutral-400"
      >
        {{ description }}
      </p>
    </label>

    <Error v-if="errorMessage" :message="errorMessage" />
  </div>
</template>

<script>
import { Select } from '@/Components/Shared/Form/Core'
import Label from './Label.vue'
import Error from './Error.vue'
import { twMerge } from 'tailwind-merge'

export default {
  components: {
    Error,
    Label,
  },
  mixins: [Select],
  setup() {
    return {
      twMerge,
    }
  },
}
</script>
