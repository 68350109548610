<script>
import { find, forEach, get } from 'lodash-es'
import Element from './Element.vue'

export default {
  mixins: [Element],
  props: {
    multiple: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    options: {
      type: [Array, Object],
      /* c8 ignore next */
      default: () => [],
      required: false,
    },
    modelValue: {
      required: false,
    },
    wrapperClass: {
      type: String,
      default: '',
    },
    defaultOption: {
      type: Object,
      default: () => {
        return {
          value: '',
          label: 'Choose...',
        }
      },
    },
  },
  computed: {
    optionsContainPlaceholder() {
      return !!find(this.arrayOptions, (option) => {
        return option.value === ''
      })
    },
    model: {
      get: function () {
        if (this.hasFormContext) {
          return get(this.formContext.model, this.name)
        }

        return this.modelValue
      },

      set: function (valueSelect) {
        this.emitInputValue(valueSelect)
      },
    },
    arrayOptions() {
      if (Array.isArray(this.options)) {
        return this.options
      }

      let mappedOptions = []

      forEach(this.options, (label, value) => {
        mappedOptions.push({ value, label })
      })

      return mappedOptions
    },
    mappedOptions() {
      let options = this.arrayOptions

      if (this.optionsContainPlaceholder || this.multiple) {
        return options
      }

      const vm = this

      return [
        vm.defaultOption
          ? {
              ...vm.defaultOption,
              placeholder: 'placeholder',
              disabled: true,
            }
          : {},
        ...options,
      ]
    },
  },
}
</script>
